:root {
  --primary: #2980B9;
  --primary-light: #5e9dc7;
  --secondary: #f50057;
}

/* NOTE: the order of these styles DO matter */
.DateInput_input {
  line-height: 28px;
  font-size: inherit;
  padding: 4px 10px;
}

.DateRangePickerInput_clearDates:hover {
  background-color: transparent;
}

.DateRangePickerInput_clearDates:hover .DateRangePickerInput_clearDates_svg {
  fill: var(--secondary);
}

.CalendarDay__today {
  /*Your styles here*/
  background-color: bisque;
}

.CalendarDay__selected_span {
  background: var(--primary);
  border-color: var(--primary);
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: var(--primary-light);
  border: 1px double var(--primary);
  color: #fff
}

.CalendarDay__hovered_span {
  background: var(--primary-light);
  color: white;
  border-color: var(--primary);
}

.CalendarDay__selected_start,
.CalendarDay__selected_end {
  background-color: var(--primary);
  border-color: var(--primary);
}

/* Will edit everything selected including everything between a range of dates */
/* .CalendarDay__selected_span { */
/* //background */
/* background: #82e0aa; */
/* //text */
/* color: white; */
/* //default styles include a border */
/* border: 1px solid var(--secondary); */
/* } */

/* // Will edit selected date or the endpoints of a range of dates */
/* .CalendarDay__selected {
  background: var(--primary-light);
  color: white;
} */

/* // Will edit when hovered over. _span style also has this property */
/* .CalendarDay__selected:hover {
  background: var(--primary-light);
  color: white;
} */

/* // Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date */
/* .CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
} */